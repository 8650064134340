
//cambiar en diferentes ramas de entornos.
const nameProyectFirebase = 'bluewayqa';
const apiKeyFirebase = 'AIzaSyDdZJ5_r5wOm8iJTptQpR3XX-D6dpwEJT4' ;
const messageSenderId= '80196997067';
const appId = '1:80196997067:web:190e7264cc227099f5704e';
const measurementId = 'G-24VE9G2PVB';
const firebaseNotificationSend = 'https://fcm.googleapis.com/fcm/send';
const keyPairCloudMessageWeb = 'BHUpdGdNh6V-LzhZN22MLlD7v2zeK4p3LkA87DXSnuraGXsD2qJBo6hQABK5o9BRDz-h9kNZQbeUc0k-YMaa57A';
//cloud messagin
const keyMessaging = 'AAAAEqwdD8s:APA91bEr0V5w5fhO2ZpWH4zWlh8hnUQfXSkibrr5-noDtD3NTA84Exjkw0f4mJ1vLB9lslb18dMCtIXq1RxpwugtxOg_2cxjH7QpLbXWTqclYa_UMa8bY8dHnfY5-UVwDVLV1D6Dctds';
    const firebase = {
        "nameProyectFirebase":nameProyectFirebase,
        "apiKeyFirebase":apiKeyFirebase,
        "messageSenderId":messageSenderId,
        "appId":appId,
        "measurementId":measurementId,
        "keyMessaging":keyMessaging,
        "firebaseNotificationSend":firebaseNotificationSend,
        "keyPairCloudMessageWeb":keyPairCloudMessageWeb
    };
  export default  firebase ;
  

