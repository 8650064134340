import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import { MapPopup } from "../MapPopup";
import M from "materialize-css";
import { deleteRowAndphotos, getEvidences } from "../../helpers/evidenceFunc";
import { CircleGif } from "../CircleGif";
import {
  createOrGetConversation,
  sendGroupMessageByConversations,
} from "../../helpers/chatFunc";
import { AuthContext } from "../../auth/authContext";
import { param } from "jquery";
//import { useGetEvidence } from '../../hooks/hooks_evidence';

export const EvidencesHome = () => {
  const itemsPerPAge = 8;
  let count = 0;
  // let {pageNumber} = useParams();

  //Global info user dispatch
  const { currentUser } = useContext(AuthContext);

  const [listEvidence, setListevidence] = useState([]);
  // const [stateLatLng,setLatLng] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [serachText, setSerachText] = useState("");
  const [locations, setLocations] = useState({});
  const [savePage, setSavePage] = useState({
    pagination: 1,
  });
  const [stateDataToMap, setDataToMap] = useState({});
  const { pagination } = savePage;
  let optionsDate = {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "numeric"
  };

  //variables to DatesPICKERS
  function subtractDays(date, days) {
    date.setDate(date.getDate() - days);

    return date.toLocaleString('es-MX', optionsDate).split(',')[1];
  }


  const defaultDate = new Date(Date.now()).toLocaleString('es-MX', optionsDate).split(',')[1];
  console.info(Date(Date.now()).toLocaleString('es-MX', optionsDate));
  const backDays = subtractDays(new Date(Date.now()), 15)
  console.info(backDays)//new Date(Date.now().setDate()).toLocaleString().split(',')[0];
  let currenDate = defaultDate; //`${defaultDate.getDate()}/${defaultDate.getMonth()}/${defaultDate.getFullYear()}`;
  const [initDate, setInitDate] = useState(backDays);
  const [endDate, setEndDate] = useState(currenDate);

  /**
   * HANDLE DATE
   */
  // const handleDate = () => {
  //   if (document.getElementById('endDate').value !== '') {

  //   }
  //   setInitDate(document.getElementById('initDate').value);
  //   setEndDate(document.getElementById('endDate').value);
  // }


  //LOAD PAGE USEEFFECT
  useEffect(() => {
    let isMounted = true;
    let modalM = document.querySelectorAll(".modal");
    let pickerDate = document.getElementById("initDate");
    let endDatePick = document.getElementById("endDate");
    M.Modal.init(modalM);

    //START DATE
    M.Datepicker.init(pickerDate, {
      format: "dd/mm/yyyy",
      autoClose: true,
      selectMonths: true,
      // onClose: handleDate(),
      i18n: {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
        cancel: 'Cancelar',
        clear: 'Limpar',
        done: 'Ok',

        previousMonth: '<',
        nextMonth: '>',
      },
      onSelect: function (date) {

        let finalDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` //new Date(date).toLocaleString().split(',')[0];
        document.getElementById("initDate").value = date.toLocaleString().split(',')[0];
        // setInitDate(date.toLocaleString().split(',')[0]);




      },
      onOpen: function () {
        let instance = M.Datepicker.getInstance(document.getElementById("initDate"));
        let splitInitDate = document.getElementById("initDate").value.split('/');
        instance.setDate(new Date(parseInt(splitInitDate[2]), parseInt(splitInitDate[1]) - 1, parseInt(splitInitDate[0])));
      },
      onClose: function () {

        setInitDate(document.getElementById("initDate").value);
      }
    });
    //END DATE
    M.Datepicker.init(endDatePick, {
      format: "dd/mm/yyyy",
      autoClose: true,
      selectMonths: true,
      // onClose: handleDate(),
      i18n: {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
        cancel: 'Cancelar',
        clear: 'Limpar',
        done: 'Ok',

        previousMonth: '<',
        nextMonth: '>',
      },
      onSelect: function (date) {

        let finalDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` //new Date(date).toLocaleString().split(',')[0];

        document.getElementById("endDate").value = date.toLocaleString().split(',')[0];
        //setEndDate(date.toLocaleString().split(',')[0]);


      },
      onOpen: function () {
        let instance = M.Datepicker.getInstance(document.getElementById("endDate"));
        let splitInitDate = document.getElementById("endDate").value.split('/');
        instance.setDate(new Date(parseInt(splitInitDate[2]), parseInt(splitInitDate[1]) - 1, parseInt(splitInitDate[0])));
      },
      onClose: function () {

        setEndDate(document.getElementById("endDate").value);
      }
    });
    //reset pagination of Incidencias to 1
    localStorage.setItem(
      "savePageInc",
      JSON.stringify({
        pagination: 1,
      })
    );
    //save the pagination

    let pagination;

    if (!localStorage.getItem("savePage")) {
      const remeberPage = localStorage.getItem("savePage");
      if (remeberPage !== null) {
        const objRemebermeData = JSON.parse(remeberPage);
        console.log("Pagination => ", objRemebermeData["pagination"]);
        setPage(objRemebermeData["pagination"]);
      }
      setSavePage({
        pagination: page,
      });
      pagination = {
        pagination: page,
      };
      localStorage.setItem("savePage", JSON.stringify(pagination));
    } else {
      const remeberPage = localStorage.getItem("savePage");
      if (remeberPage !== null) {
        const objRemebermeData = JSON.parse(remeberPage);
        console.log("Pagination => ", objRemebermeData["pagination"]);
        setPage(objRemebermeData["pagination"]);
      }
      localStorage.removeItem("savePage");
      setSavePage({
        pagination: page,
      });
      pagination = {
        pagination: page,
      };
      localStorage.setItem("savePage", JSON.stringify(pagination));
    }
    // save and get pagination END

    return () => {
      isMounted = false;
    };

    //M.FormSelect(select);
  }, []);

  //list of evidences
  const [listEvidenceState, setListEvidences] = useState({
    listEvidenceData: [],
    loading: true,
  });

  useEffect(() => {
    let isMounted = true;

    setListEvidences({
      listEvidenceData: [],
      loading: true,
    });

    if (document.getElementById("endDate").value !== '') {

      let end = new Date(document.getElementById("endDate").value); //Date.parse(document.getElementById("endDate").value);
      let init = new Date(document.getElementById("initDate").value) //Date.parse(document.getElementById("initDate").value);

      if (end.getTime() < init.getTime()) {

        //show dialog
        Swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "La Fecha final debe ser mayor a la fecha inicial.",
        });
        let endDateF = document.getElementById("endDate").value = "";
        setEndDate(endDateF);
        getEvidences(
          serachText,
          currentUser.idCompany,
          currentUser.id,
          currentUser.typeUser,
          document.getElementById('initDate').value,
          document.getElementById('endDate').value
        ).then((result) => {
          console.log("Evidencias=>", result);
          setListEvidences({
            listEvidenceData: result,
            loading: false,
          });
        });
      } else {
        getEvidences(
          serachText,
          currentUser.idCompany,
          currentUser.id,
          currentUser.typeUser,
          document.getElementById('initDate').value,
          document.getElementById('endDate').value
        ).then((result) => {
          console.log("Evidencias=>", result);
          setListEvidences({
            listEvidenceData: result,
            loading: false,
          });
        });
      }
    } else {
      getEvidences(
        serachText,
        currentUser.idCompany,
        currentUser.id,
        currentUser.typeUser,
        document.getElementById('initDate').value,
        document.getElementById('endDate').value
      ).then((result) => {
        console.log("Evidencias=>", result);
        setListEvidences({
          listEvidenceData: result,
          loading: false,
        });
      });
    }

    return () => {
      isMounted = false;
    };
  }, [page, endDate, initDate]);

  const { listEvidenceData, loading } = listEvidenceState;
  const getListEvidence = listEvidenceData;
  console.log(getEvidences);

  const onChangeSerachTextEvi = (e) => {
    //e.preventDefault();
    //const searchText = e.target.value;
    setSerachText(e.target.value);
  };

  /**
   * GET List of evidences
   */
  const retriveEvidences = () => {
    const items = getListEvidence.slice(
      (page - 1) * itemsPerPAge,
      (page - 1) * itemsPerPAge + itemsPerPAge
    );
    setListevidence(items);
    setPageCount(Math.ceil(getListEvidence.length / itemsPerPAge));
  };
  /**
   * update list each click or page change
   */
  useEffect(() => {
    let isMounted = true;
    retriveEvidences();
    return () => {
      isMounted = false;
    };
  }, [page, itemsPerPAge, listEvidenceState]);

  //serachForm
  const searchEvidence = (event) => {
    event.preventDefault();

    setListEvidences({
      listEvidenceData: [],
      loading: true,
    });

    getEvidences(
      serachText,
      currentUser.idCompany,
      currentUser.id,
      currentUser.typeUser,
      document.getElementById('initDate').value,
      document.getElementById('endDate').value
    ).then((result) => {
      console.log("DATA AFTER SEARCH => ", result);
      setListEvidences({
        listEvidenceData: result,
        loading: false,
      });
    });

    if (listEvidenceState.listEvidenceData.length > 0) {
      retriveEvidences();
    }
  };

  //page change btn
  const handlePageChange = (e) => {
    e.preventDefault();
    //console.log(e.target.id);
    const currentPage = parseInt(e.target.id);
    setPage(currentPage);
  };

  //backArrow
  const handlerBackArrowPage = () => {
    const arrowBack = document.getElementById("back");
    if (page === 1) {
      arrowBack.classList.add("disabled");
      setPage(1);
      localStorage.setItem(
        "savePage",
        JSON.stringify({
          pagination: 1,
        })
      );
    }
    if (page > 1) {
      arrowBack.classList.remove("disabled");
      const backPage = page - 1;
      setPage(backPage);
      setFormValues({ ...formValues, pageActive: backPage });
      localStorage.setItem(
        "savePage",
        JSON.stringify({
          pagination: backPage,
        })
      );
    }
  };

  //Next Arrow
  const handlerNextArrowPage = () => {
    const netxArrow = document.getElementById("next");
    if (page === pageCount) {
      netxArrow.classList.add("disabled");
      //setPage(pageCount);
    }
    if (page < pageCount) {
      netxArrow.classList.remove("disabled");
      const nextPage = page + 1;
      setPage(nextPage);
      setFormValues({ ...formValues, pageActive: nextPage });
      localStorage.setItem(
        "savePage",
        JSON.stringify({
          pagination: nextPage,
        })
      );
    }
  };

  console.log("List evidence per page ===>", listEvidence);
  const [chatState, setChatStat] = useState("");

  //getConversationID
  const handlerCreateOrGetChat = (e) => {
    e.preventDefault();
    let recipentID = e.target.id;
    //console.log(recipentID);
    createOrGetConversation(
      currentUser.id,
      recipentID,
      currentUser.idCompany
    ).then((res) => {
      console.log(res);
      setChatStat(res);
      if (res !== "") {
        showPopupMessage(res);
      } else {
        //erro pop
      }
    });
  };

  //showPopMessage
  const showPopupMessage = (chatID) => {
    Swal.fire({
      input: "textarea",
      //inputLabel: 'Message',
      title: "Enviar Mensaje",
      //text:name,
      inputPlaceholder: "Escriba su mensaje aquí...",
      inputAttributes: {
        "aria-label": "Escriba su mensaje aquí",
      },

      showCancelButton: true,
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.dismiss === "cancel") {
        setChatStat("");
      } else if (result.dismiss === "backdrop") {
        setChatStat("");
      } else {
        if (result.value !== "") {
          let listChatOnlyOne = [];
          listChatOnlyOne.push(chatID);
          //console.log('good',result.value);
          //const {selectedChats} = selectChat;
          //console.log('sendMessage to CHAT=>',listChatOnlyOne);
          await sendGroupMessageByConversations(
            listChatOnlyOne,
            currentUser.id,
            result.value
          );
          setChatStat("");
          Swal.fire({
            icon: "success",
            title: "Entrega exitosa",
            text: "El mensaje fue enviado con éxito",
          });
          //funcion para enviar mensajes por n conversaciones
        }

        if (result.value === "") {
          Swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "El mensaje no puede ir vació.",
          });
        }
      }
    });
  };

  //get Location
  const handlerLocation = (e) => {
    e.preventDefault();
    // console.log(e.target.id);
    let getLocations = e.target.id.split("|");
    setLocations({
      latitude: parseFloat(getLocations[0]),
      longitude: parseFloat(getLocations[1]),
      user_name: getLocations[2],
      vehicleNumber: getLocations[3],
      distance: parseFloat(getLocations[4]),
      dateString: getLocations[5],
      name: getLocations[6],
    });

    setDataToMap({

    });

    //setLatLng(e.target.id);
  };

  /**
   *
   * @returns delelet pictures from all evidenci  serviceID
   */
  //delete usr
  const handletDeleteRowPhotos = async (e) => {
    e.preventDefault();
    console.info(e.target.id);
    let dataToSend = e.target.id.split(",");
    console.info(dataToSend);
    Swal.fire({
      title: "Aviso",
      text: "¿Estás seguro de eliminar las evidencias?",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true,
      //confirmButtonColor:"#7cb342",
      //cancelButtonColor:"#e53935",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enviando información.",
          allowOutsideClick: false,
          allowEscapeKey: false,
          html: "<b>Por favor espere...</b>",
          didOpen: () => {
            Swal.showLoading();
          },
        });
        deleteRowAndphotos(dataToSend[0], dataToSend[1], dataToSend[2]).then(
          (res) => {
            console.log(res);
          }
        );
      } else {
        Swal.close();
      }
    });
  };

  //RENDER PAGINATION
  const renderPagination = () => {
    let listPageCount = [];
    for (let index = 0; index < pageCount; index++) {
      if (index === 0) {
        let num = 1;
        listPageCount.push(
          <li className={`${page === num ? "active" : ""}`} key={index}>
            <Link to="" onClick={handlePageChange} id={num}>
              {num}
            </Link>
          </li>
        );
      } else {
        let num = index + 1;
        listPageCount.push(
          <li className={`${page === num ? "active" : ""}`} key={index}>
            <Link to="" onClick={handlePageChange} id={num}>
              {num}
            </Link>
          </li>
        );
      }
    }

    return listPageCount;
  };
  /**
   *
   * NEW PAGINATION
   */
  const jsonPagination = JSON.parse(localStorage.getItem("savePage"));
  const getPagination = jsonPagination["pagination"];
  const initialValues = { pageActive: getPagination };

  const [formValues, setFormValues] = useState(initialValues);

  const handleGoBackPage = (e) => {
    e.preventDefault();
    let numPag = document.getElementById("pageActive").value;
    console.log(numPag);
    setPage(parseInt(numPag));

    localStorage.setItem(
      "savePage",
      JSON.stringify({
        pagination: parseInt(numPag),
      })
    );
  };
  const onChangePageInput = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const newPagination = () => {
    return (
      <div className="row">
        <div className="contentPagination">
          <Link
            className={`${page === 1 ? "disabled" : ""}`}
            to=""
            id="back"
            onClick={handlerBackArrowPage}
          >
            <i className=" medium material-icons">chevron_left</i>
          </Link>
          {/* <div className="input-field  white lighten-5 inputRegisterUser spaceBottom20"> */}
          <form className="center" onSubmit={handleGoBackPage}>
            <div className="white inputPage">
              <input
                name="pageActive"
                type="number"
                className=""
                required
                id="pageActive"
                value={formValues.pageActive}
                onChange={onChangePageInput}
                min={1}
                max={pageCount}
                style={{ textAlign: "center" }}
              />
              {/* onChangeCapture={onChangePageInput} */}
            </div>
          </form>

          {/* </div> */}

          <label className="deText center">de</label>
          <label className="deText center">{pageCount}</label>
          <Link
            className={`${page === pageCount ? "disabled" : ""}`}
            to=""
            id="next"
            onClick={handlerNextArrowPage}
          >
            <i className=" medium material-icons">chevron_right</i>
          </Link>
        </div>
      </div>
    );
  };

  const returnObservation = (evidence) => {
    let evidenceTxt = '';
    if (evidence.observation !== "") {
      if (evidence.observation.length >= 20) {
        evidenceTxt = evidence.observation.substring(0, 15)
      } else {
        evidenceTxt = evidence.observation;
      }
    } else {
      evidenceTxt = 'NA';
    }
    return evidenceTxt;
  }

  //renderTable Information
  const listTableEvidence = () => {
    if (listEvidence.length > 0) {
      return (
        <div className="contentTable">
          <table className="striped highlight  responsive-table centered tableFont">
            <thead className="borderbotomLine">
              <tr>
                <th className="center">Fecha</th>
                <th className="center">Operador</th>
                <th className="center">Unidad</th>
                <th className="center">Origen</th>
                <th className="center">Destino</th>
                <th className="center">Orden Servicio</th>
                <th className="center">Cliente</th>
                <th className="center">Observación</th>
                <th className="center">Núm</th>
                {/*<th className='center'>Observación</th>*/}
                <th className="center" colSpan={3}>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {listEvidence.map((evidence) => {
                count++;

                return (
                  <tr
                    className={
                      evidence.cantNewEvidences > 0 ? "newsEvidences" : ""
                    }
                    id={count % 2 === 0 ? "rowHighLigth" : ""}
                    key={`${evidence.dateString}-${count}`}
                  >
                    <td>{evidence.dateString}</td>
                    <td>{evidence.userName}</td>
                    <td>{evidence.vehicleNumber}</td>
                    <td>{evidence.origin}</td>
                    <td>{evidence.destination}</td>
                    <td>{evidence.serviceGuide}</td>
                    <td>{evidence.name}</td>

                    <td><abbr title={`${evidence.observation}`} style={{ textDecoration: "unset" }}>
                      {
                        returnObservation(evidence)
                        // incidence.observation !== ""
                        //   ? incidence.observation.substring(0, 15)
                        //   : "NA"
                      }</abbr>
                    </td>
                    <td>{evidence.numEvidences}</td>
                    <td>
                      <div className="optionsEvi">
                        {currentUser.typeUser === 3 ? (
                          <Link
                            to=""
                            id={evidence.userUid}
                            onClick={handlerCreateOrGetChat}
                            style={{ marginRight: "10px" }}
                          >
                            <i
                              className="material-icons iconsList"
                              id={evidence.userUid}
                              style={{ pointerEvents: "none" }}
                            >
                              question_answer
                            </i>
                          </Link>
                        ) : (
                          <></>
                        )}
                        <Link
                          to={`/evidencias/${evidence.serviceDetail_ID}`}
                          id={evidence.id}
                          style={{ marginRight: "10px" }}
                        >
                          <i
                            className="material-icons iconsList"
                            id={evidence.id}
                          >
                            collections
                          </i>
                        </Link>
                        <Link
                          to=""
                          data-target="modal1"
                          className=" modal-trigger"
                          id={`${evidence.statusLat}|${evidence.statusLon}|${evidence.userName}|${evidence.vehicleNumber}|${evidence.distanceRemain}|${evidence.dateString} | ${evidence.name}`}
                          onClick={handlerLocation}
                        >
                          <i
                            className="material-icons iconsList"
                            style={{ pointerEvents: "none" }}
                          >
                            pin_drop
                          </i>
                        </Link>
                      </div>
                      {/**new option delete images */}
                      {currentUser.typeUser === 3 ? (
                        <div
                          style={{
                            marginLeft: "0px",
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                          onClick={handletDeleteRowPhotos}
                        >
                          <i
                            className="material-icons iconsInputs red-text"
                            id={`${currentUser.idCompany},${evidence.serviceDetail_ID},${evidence.id}`}
                          >
                            delete_forever
                          </i>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {newPagination()}
          {/* <div className='row center'>
                <ul className='pagination'>
                    <li className={`${page===1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                        {renderPagination()}
                    <li className={`${page===pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                </ul>
            </div> */}
        </div>
      );
    } else {
    }
  };


  /**
   * CHANGE DATES
   */
  const handleChangeDates = (e) => {
    //e.preventDefault();

    console.log(e.target);
  }

  return (
    <div className="">
      <div className="containerEvid">
        <div className="" style={{ background: "#5E5E68" }}>
          <form
            className="center"
            style={{ padding: "10px 0px" }}
            onSubmit={searchEvidence}
          >
            <div className="input-field inputSerach ">
              <i
                className="material-icons  prefix "
                style={{ color: "#A3A3A9", left: "0px", top: "10px" }}
              >
                search
              </i>
              <input
                id="search"
                type="text"
                placeholder="Buscar"
                className="inputSerachBorderBottom"
                value={serachText}
                onChange={onChangeSerachTextEvi}
              />
            </div>
          </form>
        </div>

        <div className="row center">
          <h4>EVIDENCIAS</h4>
        </div>

        {/** FILTER DATES */}
        <div className="row">
          <div className="col s12 m3 l3"></div>
          <div className="col s12 m3 l3"> <div className="input-field inputDate"><i className="material-icons prefix" style={{ color: "#A3A3A9", left: "0px", top: "10px" }} >today</i><i /><input className=" datepicker " type="text" id="initDate" value={initDate} onChange={handleChangeDates} /></div> </div>
          <div className="col s12 m3 l3"> <div className="input-field inputDate"><i className="material-icons prefix" style={{ color: "#A3A3A9", left: "0px", top: "10px" }} >today</i><i /><input className=" datepicker " type="text" id="endDate" value={endDate} placeholder={endDate === '' ? 'Fecha Final' : ''} onChange={handleChangeDates} /></div></div>
          <div className="col s12 m3 l3"></div>
        </div>
        {/** FILTER DATES */}

        {loading === true ? (
          <div className="row center">
            <CircleGif />
          </div>
        ) : (
          listTableEvidence()
        )}
      </div>

      {/*renderMapPop(stateLatLng)*/}

      {
        locations.latitude !== 21 && locations.longitude !== -100 ?
          <MapPopup
            latitude={locations.latitude}
            longitude={locations.longitude}
            infoData={locations}
          />
          : <div></div>
      }
    </div>
  );
};
