

import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

import M from 'materialize-css';
import { getUsers } from '../../helpers/usersFunc';
import { CircleGif } from '../CircleGif';

import { AuthContext } from '../../auth/authContext';
import { deleteUserfromAll } from '../../helpers/registerFunc';
import { getExpenses, removeExpense } from '../../helpers/conceptosFunc';

export const ConceptoGastosIndex = () => {

    const itemsPerPAge = 8;
    let count = 0;

    //Global info user dispatch
    const { currentUser } = useContext(AuthContext);

    const [listGastos, setListGastos] = useState([]);
    // const [stateLatLng,setLatLng] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");
    /*
    const [locations,setLocations] = useState({
        latitude:undefined,
        longitude:undefined
    });
    const [stateDataToMap, setDataToMap] = useState({
        
    });
    */


    useEffect(() => {

        let isMounted = true;
        let modalM = document.querySelectorAll('.modal');
        M.Modal.init(modalM);
        //reset the paginations of evi and inci
        localStorage.setItem('savePage', JSON.stringify({
            pagination: 1
        }));
        localStorage.setItem('savePageInc', JSON.stringify({
            pagination: 1
        }));
        return () => {
            isMounted = false;
        };
    }, []);

    //list of users
    const [listGastosState, setListGastoss] = useState({
        listGastosData: [],
        loading: true,
    });
    useEffect(() => {
        let isMounted = true;
        getExpenses(currentUser.idCompany, serachText).then((result) => {
            console.log(result);
            setListGastoss({
                listGastosData: result,
                loading: false,
            })
        })
        return () => {
            isMounted = false;
        };
    }, [page]);
    const { listGastosData, loading } = listGastosState;
    const getListUser = listGastosData;



    const onChangeSerachTextUser = (e) => {
        //e.preventDefault();
        //const searchText = e.target.value;
        setSerachText(e.target.value);
    }


    /**
    * GET List of users
    */
    const retriveUsers = () => {
        //const endOffset = (page) + itemsPerPAge;
        const items = getListUser.slice((page - 1) * itemsPerPAge, (page - 1) * itemsPerPAge + itemsPerPAge);
        console.log(items);
        setListGastos(items);
        setPageCount(Math.ceil(getListUser.length / itemsPerPAge));
    }
    /**
     * update list each click or page change
     */
    useEffect(() => {
        let isMounted = true;
        retriveUsers();
        return () => {
            isMounted = false;
        };
    }, [page, itemsPerPAge, listGastosState]);

    //serachForm
    const searchUser = (event) => {

        event.preventDefault();

        setListGastoss({
            listGastosData: [],
            loading: true,
        });

        getExpenses(currentUser.idCompany, serachText).then((result) => {
            console.log(result);
            setListGastoss({
                listGastosData: result,
                loading: false,
            })
        })

        if (listGastosState.listGastosData.length > 0) {
            retriveUsers();
        }



    }




    //page change btn
    const handlePageChange = (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        setPage(currentPage);
    };


    //backArrow
    const handlerBackArrowPage = () => {
        const arrowBack = document.getElementById('backli');
        if (page === 1) {
            arrowBack.classList.add('disabled');
            setPage(1);
        }
        if (page > 1) {
            arrowBack.classList.remove('disabled');
            const backPage = page - 1;
            setPage(backPage);
        }

    }

    //Next Arrow
    const handlerNextArrowPage = () => {
        const netxArrow = document.getElementById('nextli');
        if (page === pageCount) {
            netxArrow.classList.add('disabled');
            //setPage(pageCount);
        }
        if (page < pageCount) {
            netxArrow.classList.remove('disabled');
            const nextPage = page + 1;
            setPage(nextPage);
        }
    }

    //delete usr
    const handletDeleteUser = async (e) => {
        e.preventDefault();
        console.info(e.target.id)
        // let dataToSend = e.target.id.split(",")
        // console.info(dataToSend)
        Swal.fire({
            title: "Aviso",
            text: "¿Estas seguro de eliminar el concepto?",
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            //confirmButtonColor:"#7cb342",
            //cancelButtonColor:"#e53935",  
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Enviando información.',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    html: '<b>Por favor espere...</b>',
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                removeExpense(e.target.id).then((res) => {
                    console.log("response the function")
                });
            } else {
                Swal.close()
            }
        })
    }






    //RENDER PAGINATION
    const renderPagination = () => {
        let listPageCount = [];
        for (let index = 0; index < pageCount; index++) {

            if (index === 0) {
                let num = 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)

            } else {
                let num = index + 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)

            }

        }

        return listPageCount;
    }


    //renderTable Information 
    const listTableUser = () => {
        if (listGastos.length > 0) {
            return (
                <div className='contentTable'>


                    <table className='striped highlight  responsive-table  tableFont'>
                        <thead className='borderbotomLine'>
                            <tr>
                                <th className=''>Nombre</th>

                                <th className=''>Opciones</th>

                            </tr>
                        </thead>
                        <tbody>
                            {

                                listGastos.map((gastos) => {
                                    count++;
                                    return <tr
                                        id={count % 2 === 0 ? 'rowHighLigth' : ''}
                                        key={`${count}`}>
                                        <td>{gastos.comboNameES}</td>

                                        {/* <td>{gastos.active === '0' ? <span>Inactivo</span> : <span>Activo</span>}</td> */}

                                        <td>
                                            <div className='optionsEvi'>
                                                <Link to={`/conceptosGastos/editar/${gastos.idDocumento}`} id={gastos.idDocumento}>
                                                    <i className='material-icons iconsInputs'>edit</i>
                                                </Link>

                                                {currentUser.typeUser === 2 ? <div style={{ marginLeft: "15px", cursor: "pointer" }} onClick={handletDeleteUser}><i className='material-icons iconsInputs red-text' id={gastos.idDocumento}>delete_forever</i></div> : <div></div>}
                                            </div>

                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    <div className='row center'>
                        <ul className='pagination'>
                            <li className={`${page === 1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                            {renderPagination()}
                            <li className={`${page === pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                </div>
            )
        } else { }
    }



    return (
        <>
            <div className='containerUser'>

                <div className="" style={{ background: '#5E5E68' }}>
                    <form className='center' style={{ padding: '10px 0px' }} onSubmit={searchUser}>
                        <div className="input-field inputSerach ">
                            <i className="material-icons  prefix " style={{ color: '#A3A3A9', left: '0px', top: '10px' }}>search</i>
                            <input id="search" type="text" placeholder='Buscar' className='inputSerachBorderBottom' value={serachText} onChange={onChangeSerachTextUser} />

                        </div>
                    </form>
                </div>

                <div className='row center'><h4>Conceptos de gastos</h4></div>
                <div className='contentBtnUsers'>
                    <NavLink to="/conceptosGastos/registrar" className='btn waves-effect waves-light btnFromRegisterCancel' style={{ marginRight: '10px' }}>
                        Registrar
                    </NavLink>

                </div>
                {loading === true ? <div className='row center'><CircleGif /></div> : listTableUser()}

            </div>

        </>


    )
}
